@font-face {
  font-family: 'Tragic Marker';
    src: url('TragicMarker-Regular.eot');
    src: url('TragicMarker-Regular.eot?#iefix') format('embedded-opentype'),
        url('TragicMarker-Regular.woff2') format('woff2'),
        url('TragicMarker-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url("bg.jpg");
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#fileInput {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-container {
  /*position: absolute;*/
  /*top: 0;*/
  /*bottom: 0;*/
  width: 100%;
  height: 70vh;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

.leaflet-bar a {
  background-color: rgba(255, 255, 255, 0.8);
}

.mapboxgl-ctrl-attrib-inner {
  font-size: 6pt;
}